export const MASTER_DATA = "master_data";
export const SYSTEM_DATA = "system_data";
export const AUTH = 'auth';
export const PERMISSIONS = 'permissions';
export const CONFIG = 'config';
export const INVESTIGATION = 'investigation';
export const USER_DATA = "user_data";
export const HOSPITAL_DATA = "hospital_data";
export const PRESCRIPTION_DOCTORS = 'prescription_doctors';
export const DATES = 'dates';
export const PATIENT_SEARCH_QUERIES = 'patient_search_queries';
export const LAST_VISITED_PAGE = 'last_visited_page';
export const SELECTED_ROW = 'selected_row';
export const YEARS_TOTAL = 40;
export const RUPEE = "₹";
