// utils/permissionUtils.js
import { PERMISSIONS } from "../Constants/storageConstant";
import { getLocalData, isSuperAdmin } from "../Services/common";

const canAccessModule = (moduleName = '') => {
    if (isSuperAdmin()) {
        return true
    } else {
        const permissions = getLocalData(PERMISSIONS);
        return permissions?.some(p => `view_${moduleName}` === p?.name);
    }
};

export default canAccessModule;
