import { memo } from "react";
import SyncLoader from "react-spinners/SyncLoader";
import "./loader.scss";
import TipsComponent from "../Tips/Tips";

const MainLoader = memo(() => {
  const override = {
    display: "block",
    borderColor: "var(--primary)",
  };
  return (
    <>
      <div className="h-100 w-100 d-flex align-items-center justify-content-center">
        <div className="in_screen_loader">
          <SyncLoader
            color={"var(--primary)"}
            cssOverride={override}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <TipsComponent />
        </div>
      </div>
    </>
  );
});

export default MainLoader;
