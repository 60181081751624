export const EMAIL_REQUIRED = "Please enter the email";
export const PASSWORD_REQUIRED = "Please enter the password";
export const EMAIL_PASSWORD_REQUIRED = "Please enter email and password";
export const FIRST_NAME_REQUIRED = "Please enter First Name";
export const PHOTO_REQUIRED = "Please upload your photo";
export const FIRST_NAME_LIMIT_ERROR = 'First Name should be less than 30 characters';
export const LAST_NAME_REQUIRED = "Please enter Last Name";
export const LAST_NAME_LIMIT_ERROR = 'Last Name should be less than 30 characters';
export const CONFIRM_PASSWORD_REQUIRED = "Please confirm your password";
export const PASSWORD_CONFIRM_PASSWORD_MATCH = "Password and confirm password do not match";
export const OLD_PASSWORD_REQUIRED = "Please enter Old Password";
export const NEW_PASSWORD_REQUIRED = "Please enter New Password";
export const SAME_OLD_NEW_PASS = "Old Password and New Password cannot be the same";
export const NEW_CONFIRM_PASSWORD_REQUIRED = "Please confirm your Password";
export const INVALID_PASSWORD = "Please enter password between 6 to 12 characters";
export const OTP_REQUIRED = "Please enter OTP to proceed";
export const VALID_OTP = "Please enter 4 Digit OTP to proceed";
export const OTP_SENT_SUCCESS = "Email Validation OTP has been sent successfully";
export const OTP_SENT_FAILURE = "Please retry after 30 seconds.";
export const SOMETHING_WENT_WRONG = "We seem to have hit a snag. Please retry in a while.";
export const NAME_REQUIRED = (name = "") => { return `Please enter the ${name ? name + ' ' : ''}Name`; };
export const LATLNG_REQUIRED = "Please enter the Lat/Lng";
export const LATLNG_INVALID = "Please enter correct Lat/Lng";
export const IMAGE_REQUIRED = "Please add an Image";
export const COLOR_REQUIRED = "Please select primary color";
export const HOSPITAL_SERVICES_REQUIRED = "Please enter Services";
export const PHONE_NUMBER_REQUIRED = "Please enter the phone number";
export const PHONE_NUMBER_INCORRECT = "Please enter correct phone number";
export const MESSAGE_REQUIRED = "Please enter the message";
export const INCORRECT_EMAIL = "The email must be a valid email address";
export const INCOMPLETE_PROFILE_ERROR = "Please complete your profile!";
export const MIN_MAX_EXPERIENCE_ERROR = "Maximum experience should be greater than minimum experience";
export const TOTAL_POSITIONS_REQUIRED_ERROR = "Please enter number of positions";
export const TOTAL_POSITIONS_LIMIT_ERROR = "Number of positions must be greater than 0";
export const USER_REGISTRATION_ISSUE = "Issue with User Registration";
export const VALID_LINK = "Please enter a valid link";
export const BUTTON_TEXT_OK = "OK";
export const DATEPICKER_PLACEHOLDER = "DD/MM/YYYY";
export const LOGIN_FAIL = "Unable to find user with this Email and Password, Please create an account."
export const LOGIN_SUCCESS = "Logged In Successfully"
export const RESET_PASSWORD_SUCCESS = "Password Reset Successfully"
export const RESET_PASSWORD_FAIL = "Password Reset Failed"
export const BROWSER_STORAGE_ERROR = "Your web browser does not support storing settings locally. Some settings may not save or some features may not work properly for you.";
export const MEDICINE_NAME_REQUIRED = 'Please add Medicine Name';
export const MEDICINE_NAME_LENGTH = 'Medicine Name should be at least 3 characters long';
export const SERVICE_NAME_LENGTH = 'Service Name should be at least 3 characters long';
export const MEDICINE_TYPE_REQUIRED = 'Please add Medicine Type';
export const SERVICE_TYPE_REQUIRED = 'Please add Service Type';
export const MEDICINE_DESC_REQUIRED = 'Please add Medicine Description';
export const SERVICE_DESC_REQUIRED = 'Please add Service Description';
export const DESC_REQUIRED = 'Please add Description';
export const MEDICINE_DESC_LENGTH = 'Medicine Description should be at least 10 characters long';
export const SERVICE_DESC_LENGTH = 'Service Description should be at least 10 characters long';
export const BILL_DATE_REQUIRED = 'Please enter Bill Date';
export const BILL_NO_REQUIRED = 'Please enter Bill Number';
export const BILL_NO_LENGTH = 'Please enter 14 Digit Bill Number';
export const SUPPLIER_REQUIRED = 'Please add Supplier Name';
export const PURCHASE_PRICE_REQUIRED = 'Please enter Purchase Price';
export const SALE_PRICE_REQUIRED = 'Please enter Sale Price';
export const QUANTITY_REQUIRED = 'Please enter Quantity';
export const EXPIRY_DATE_REQUIRED = 'Please enter Expiry Date';
export const GST_REQUIRED = 'Please enter GST Percentage';
export const SGST_REQUIRED = 'Please enter SGST';
export const CGST_REQUIRED = 'Please enter CGST';
export const GST_TYPE_REQUIRED = 'Please add GST Type';
export const DISCOUNT_REQUIRED = 'Please enter Discount value';
export const NET_AMOUNT_REQUIRED = 'Please enter Net Amount';
export const PAYMODE_REQUIRED = 'Please add Pay Mode';
export const PATIENT_FOUND = 'Patient found with given details and the data has been retrieved!';
export const PATIENT_NOT_FOUND = 'No Patient found, please fill data manually, or try with different details!';
export const PATIENT_ID_FOUND = 'Patient found!';
export const PATIENT_ID_NOT_FOUND = 'No Patient found!';
export const PATIENT_PHOTO_REQUIRED = 'Please add profile photo';
export const FEE_REQUIRED = 'Please add Fee Amount';
export const WEBCAM_ERROR = 'Error accessing Webcam. Please allow access or try again!';
export const PATIENT_AADHAAR_REQUIRED = 'Please enter Aadhaar Number';
export const PATIENT_AADHAAR_LENGTH = 'Aadhaar Number should be of 12 digits';
export const PATIENT_ONLINE_ID_REQUIRED = 'Please enter 12 Characters Online ID';
export const PATIENT_FILE_NO_REQUIRED = 'Please enter File Number';
export const PATIENT_PNDT_REQUIRED = 'Please enter PNDT';
export const PATIENT_CO_REQUIRED = `Please enter Father's Name`;
export const DOB_REQUIRED = 'Please enter Date of Birth';
export const GENDER_REQUIRED = 'Please select Gender';
export const VISIT_DATE_REQUIRED = 'Please select Visit Date';
export const DEPARTMENT_REQUIRED = 'Please add Department';
export const DOCTOR_REQUIRED = 'Please add Doctor Name';
export const ADDRESS_REQUIRED = 'Please enter Address';
export const DAYS_REQUIRED = 'Please enter Days';
export const CITY_REQUIRED = 'Please enter City';
export const PRESCRIPTION_DESCRIPTION_REQUIRED = 'Please enter Prescription Instructions';
export const VITALS_REQUIRED = 'Please enter Vitals';
export const BP_REQUIRED = 'Please enter BP/SO2';
export const HEIGHT_WEIGHT_REQUIRED = 'Please enter HEIGHT/WEIGHT';
export const HISTORY_REQUIRED = 'Please enter History';
export const DIAGNOSIS_REQUIRED = 'Please enter Diagnosis';
export const REMARKS_REQUIRED = 'Please enter Remarks';
export const Total_REQUIRED = 'Please enter Total';
export const TOTAL_PAYABLE_REQUIRED = 'Please enter Total Payable amount';
export const TOTAL_UNITS_REQUIRED = 'Please enter Total Units';
export const MEDICINE_ALREADY_ADDED = 'Medicine already added';
export const SERVICE_ALREADY_ADDED = 'Service already added';
export const SERVICE_REQUIRED = 'Please select a Service';
export const AVAILABLE_MEDICINE_QTY = 'Total available Medicine Quantity: ';
export const NO_DATA_FOUND = 'No data found';
export const ADD_SOME_DATA = 'Please add some data to continue';
export const BATCH_NUMBER = 'Please add Batch Number';
export const UNIT_PRICE = 'Please add Unit Price';
export const NOT_ACCESSABLE = "You can't perform this action";
export const PAID_AMT_ERROR = "Paid Amount should not be more than Due Amount";
export const DISCOUNT_AMT_ERROR = "Discount Amount should not be more than Sub Total";
export const DUE_AMOUNT_ZERO = "Due Amount cannot be less than Zero";
export const MAX_AMT_LIMIT_REACHED = `Max ₹99,999.00 Amount is allowed`;
export const MAX_PERCENTAGE_LIMIT_REACHED = `Max 100% is allowed`;
export const MAX_QTY_LIMIT_REACHED = `Max 99,999 Quantity is allowed`;
export const MAX_DAYS_LIMIT_REACHED = `Max 90 Days are allowed`;
export const ACTION_NOT_PERFORMABLE = `Cannot perform this action, this medicine's stock is already in use`;
export const MAX_100_CHARS = `Max 100 characters allowed`;
export const MAX_50_CHARS = `Max 50 characters allowed`;
export const MAX_30_CHARS = `Max 30 characters allowed`;
export const MAX_20_CHARS = `Max 20 characters allowed`;
export const MAX_10_CHARS = `Max 10 characters allowed`;
export const EDIT_NOT_ALLOWED = "Edit not allowed";
export const EARLY_VISIT_REASON_REQUIRED = "Please fill Early Visit Reason";
export const FOLLOWUP_DATE_REQUIRED = "Please select a follow-up date";
export const minimumLengthRequired = (length) => {
    return `Minimum ${length} characters required`
}
export const maximumLengthRequired = (length) => {
    return `Maximum ${length} characters required`
}
export const maximumLengthAllowed = (length) => {
    return `Maximum ${length} characters Allowed`
}

export const MEDICINE_OUT_OF_STOCK = `No Batch found for selected medicine`;
export const MEDICINE_ORDER_ALREADY_CREATED = `Sold medicine can't be removed from the order. Instead, please handle it as a sale return`;
export const STATUS_REQUIRED = `Please select Status`;
export const ROLE_REQUIRED = `Please select Role`;
export const POC_REQUIRED = `Please Enter POC`;
export const PASSWORD_FIELD_INFO = "Leave Password field untouched if you don't want to change Password";
export const PAGE_NOT_ACCESSABLE = "You cannot access this page";
export const SELECT_DATE_RANGE = "Select date range";
export const DOCTOR_TITLE = "Please enter Doctor's Title";
export const DOCTOR_DEGREE = "Please enter Doctor's Degree";
export const ADDITIONAL_INFO = "Please enter Additional Info";
export const SIGNATURE_REQUIRED = "Please upload a signature";
export const USER_REQUIRED = "Please select a User";
export const ENABLE_REQUIRED_CONFIG = "Mobile number or File number or Aadhar card or Online atleast 1 field should enable & mandatory";
export const PRODUCT_ID_REQUIRED = 'Please enter Product ID';
export const PRODUCT_DETAIL_ID_REQUIRED = 'Please enter Product Detail ID';
export const CN_NUMBER_REQUIRED = 'Please enter CN Number';
export const CN_AMOUNT_REQUIRED = 'Please enter CN Amount';
export const RETURN_DATE_REQUIRED = 'Please enter Return Date';
export const POC_NAME_REQUIRED = 'Please enter POC Name';
export const POC_NUMBER_REQUIRED = 'Please enter POC Number';
export const CN_RX_BY_REQUIRED = 'Please select CN Received By';