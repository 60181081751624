import { Helmet } from 'react-helmet';

const ENVConfig = () => {

    const { REACT_APP_TITLE, REACT_APP_DESC, REACT_APP_FAVICON, REACT_APP_MANIFEST, REACT_APP_NAME, REACT_APP_THEME_COLOR, REACT_APP_APPLE_ICON } = process.env;

    return (
        <Helmet>
            <title>{REACT_APP_TITLE}</title>
            <meta name="description" content={REACT_APP_DESC} />
            <link rel="icon" href={REACT_APP_FAVICON} />
            <link rel="manifest" href={REACT_APP_MANIFEST} />
            <meta name="application-name" content={REACT_APP_NAME} />
            <meta name="theme-color" content={REACT_APP_THEME_COLOR} />
            <link rel="apple-touch-icon" href={REACT_APP_APPLE_ICON} />
        </Helmet>
    );
};

export default ENVConfig;
