import { Mixpanel } from './mixPanel.js';
import { analyticsConstant } from '../Constants/analyticsConstants';
import { getLocalData, getMedicineName, getServiceName } from '../Services/common.js';
import { HOSPITAL_DATA } from '../Constants/storageConstant.js';

const trackEvent = (eventName, data) => {
    Mixpanel.track(eventName, data);
};

export const KEYS = {
    ID: 'id',
    HOSPITAL_ID: 'Hospital Id',
    HOSPITAL_NAME: 'Hospital Name',
    PATIENT_ID: 'Patient Id',
    PATIENT_VISIT_ID: 'Patient Visit Id',
    MEDICINE_ID: 'Medicine Id',
    MEDICINE_NAME: 'Medicine Name',
    MEDICINE_MASTER_ID: 'Medicine Master Id',
    SERVICE_ID: 'Service Id',
    SERVICE_NAME: 'Service Name',
    SERVICE_MASTER_ID: 'Service Master Id',
    REPORT_NAME: 'Report Name',
    RESPONSE_TIME_MS: 'Response Time (ms)',
    FULL_URL: 'Full URL',
    ORIGIN: 'ORIGIN',
    ENDPOINT: 'ENDPOINT',
    MSG: 'message',
    STATUS: 'Status',
    SOLD_MEDICINE_NAME: 'Sold Medicine Name',
};

const getDefaultParams = () => {
    const hospitalData = getLocalData(HOSPITAL_DATA);

    return {
        [KEYS.HOSPITAL_NAME]: hospitalData?.name || '-',
        [KEYS.HOSPITAL_ID]: hospitalData?.id || '-',
    };
};

export const setUser = (data) => {
    Mixpanel.identify(data.email);
    Mixpanel.people.set({
        [KEYS.ID]: data?.id,
        $name: data?.name,
        role: data?.role,
        $email: data?.email
    });
};

export const trackPatientVisit = (patientId, patientVisitId) => {
    trackEvent(analyticsConstant.PATIENT_VISIT, {
        ...getDefaultParams(),
        [KEYS.PATIENT_ID]: patientId,
        [KEYS.PATIENT_VISIT_ID]: patientVisitId
    });
};

export const trackMedicineSale = (medicines) => {
    medicines.forEach(medicine => {
        trackEvent(analyticsConstant.MEDICINE_SALE, {
            ...getDefaultParams(),
            [KEYS.MEDICINE_ID]: medicine.medicine,
            [KEYS.MEDICINE_NAME]: getMedicineName(medicine.medicine),
            [KEYS.MEDICINE_MASTER_ID]: medicine.product_detail_id
        });
    });
};

export const trackServiceSale = (services) => {
    services.forEach(service => {
        trackEvent(analyticsConstant.SERVICE_SALE, {
            ...getDefaultParams(),
            [KEYS.SERVICE_ID]: service.service,
            [KEYS.SERVICE_NAME]: getServiceName(service.service),
            [KEYS.SERVICE_MASTER_ID]: service.id
        });
    });
};

export const trackAadharSearch = () => {
    trackEvent(analyticsConstant.AADHAR_SEARCH, {
        ...getDefaultParams(),
    });
};

export const trackMobileNumberSearch = () => {
    trackEvent(analyticsConstant.MNO_SEARCH, {
        ...getDefaultParams(),
    });
};

export const trackFileNumberSearch = () => {
    trackEvent(analyticsConstant.FILE_SEARCH, {
        ...getDefaultParams(),
    });
};

export const trackOnlineIdSearch = () => {
    trackEvent(analyticsConstant.ONLINE_ID_SEARCH, {
        ...getDefaultParams(),
    });
};

export const trackWarning = (message) => {
    trackEvent(analyticsConstant.WARNING, {
        ...getDefaultParams(),
        [KEYS.MSG]: message
    });
};

export const trackPatientStatusFilter = (status) => {
    trackEvent(analyticsConstant.PATIENT_STATUS_FILTER, {
        ...getDefaultParams(),
        [KEYS.STATUS]: status
    });
};

export const trackPatientSearch = () => {
    trackEvent(analyticsConstant.PATIENT_SEARCH, {
        ...getDefaultParams(),
    });
};

export const trackPatientDateFilter = () => {
    trackEvent(analyticsConstant.PATIENT_DATE_FILTER, {
        ...getDefaultParams(),
    });
};

export const trackSaleReturn = (saleReturnList) => {
    saleReturnList.forEach((sales) => {
        trackEvent(analyticsConstant.SALE_RETURN, {
            ...getDefaultParams(),
            [KEYS.SOLD_MEDICINE_NAME]: getMedicineName(sales.medicine)
        });
    });
};

export const trackReportView = (reportName) => {
    trackEvent(analyticsConstant.REPORT_VIEW, {
        ...getDefaultParams(),
        [KEYS.REPORT_NAME]: reportName
    });
};

export const trackReportDownload = (reportName) => {
    trackEvent(analyticsConstant.REPORT_DOWNLOAD, {
        ...getDefaultParams(),
        [KEYS.REPORT_NAME]: reportName
    });
};

export const trackAPITime = (time, url, status) => {
    let baseUrl = url;
    let endPoint = url;

    try {
        const parsedUrl = new URL(url);
        endPoint = parsedUrl.pathname.replace('/api', 'api') || '-';
        baseUrl = parsedUrl.origin;
    } catch (error) {
        console.error('Error extracting base URL:', error);
    }

    trackEvent(analyticsConstant.API_TIME, {
        ...getDefaultParams(),
        [KEYS.RESPONSE_TIME_MS]: time,
        [KEYS.STATUS]: status,
        [KEYS.FULL_URL]: url,
        [KEYS.ORIGIN]: baseUrl,
        [KEYS.ENDPOINT]: endPoint,
    });
};
