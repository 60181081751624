import { toast } from "react-toastify";

export const isNullOrEmpty = (data) => {
    return data === null || data === undefined || data.toString().trim().length < 1
}

export const slice = (data, length = 10) => {
    let newData = data;

    if (data?.length > length) {
        newData = data?.slice(0, length) + '...';
    }

    return newData;
}

export const isValidName = (data, length = 3) => {
    return !isNullOrEmpty(data) && data.toString()?.length >= length;
}

export const isEqualLength = (data, length) => {
    return !isNullOrEmpty(data) && data.toString()?.length === length;
}

export const isLengthInBetween = (data, length1, length2) => {
    return !isNullOrEmpty(data) && (data.toString().length > length1 && data.toString().length <= length2) ? true : false;
}

export function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase() || "";
}

export const extractLatLng = (input) => {
    if (!input || typeof input !== 'string') {
        toast.warn('Input must be a non-empty string');
    }

    const [latitude, longitude] = input?.split(',').map(coord => coord?.trim());

    if (isNaN(latitude) || isNaN(longitude)) {
        toast.warn('Invalid latitude or longitude value');
    }

    return { latitude: parseFloat(latitude), longitude: parseFloat(longitude) };
};
