import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as storageConstants from '../Constants/storageConstant'
import * as commonServices from '../Services/common'
import { warn } from '../Wrapper/toast/toast'
import { PAGE_NOT_ACCESSABLE } from '../Constants/stringConstants'
import canAccessModule from '../Utils/permissionUtils'

const ProtectedRoute = ({ Component, pageName, moduleName }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isAuthenticated = commonServices.getLocalData(storageConstants.AUTH);

    if (!isAuthenticated) {
      navigate("/");
    }
    else if (isAuthenticated) {
      if (location.pathname === "/") {
        navigate(commonServices.isSuperAdmin() ? "/dashboard" : "/patients");
      }
      else if ((moduleName && !canAccessModule(moduleName) && !commonServices.isSuperAdmin()) || !commonServices.canAccessPage(pageName)) {
        navigate(commonServices.isSuperAdmin() ? "/dashboard" : "/patients");
        warn(PAGE_NOT_ACCESSABLE);
      }
    }
  }, [location.pathname]);

  return (
    <>
      {Component}
    </>
  )
}

export default ProtectedRoute;
